import {
  container,
  hexToRgb,
  primaryColor,
  title,
  whiteColor,
  grayColor,
} from "assets/jss/material-kit-pro-react.js"

const sectionStyle = {
  section: {
    padding: "40px 0px",
    textAlign: "left",
  },
  container: {
    ...container,
    zIndex: "2",
  },
  title: {
    ...title,
    marginBottom: "40px",
    marginTop: "0px",
    minHeight: "32px",
    textTransform: "uppercase",
  },
  primary: {
    background:
      "linear-gradient(60deg," + primaryColor[1] + "," + primaryColor[2] + ")",
    "& h1 small": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
    },
    color: grayColor[0],
  },
  top: {
    borderTopLeftRadius: "inherit",
    borderTopRightRadius: "inherit",
  },
  bottom: {
    borderBottomLeftRadius: "inherit",
    borderBottomRightRadius: "inherit",
  },
}

export default sectionStyle
