import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// Gatsby image background
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

// core components
import styles from "assets/jss/material-kit-pro-react/components/cardStyle.js"

const useStyles = makeStyles(styles)

export default function CardGatsbyBgImg(props) {
  const {
    className,
    children,
    image,
    raised,
    darken,
    ...rest
  } = props
  const classes = useStyles()
  const cardClasses = classNames({
    [classes.cardGatsbyBgImg]: true,
    [classes.cardRaised]: raised,
    [classes.cardDarken]: darken,
    [className]: className !== undefined,
  })

  return (
    <BackgroundImage
      {...convertToBgImage(image)}
      className={cardClasses}
      preserveStackingContext
      {...rest}
    >
      {children}
    </BackgroundImage>
  )
}

CardGatsbyBgImg.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
  raised: PropTypes.bool,
  darken: PropTypes.bool,
  children: PropTypes.node,
}
