import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// Style
import styles from "assets/jss/app/components/sectionStyle"

const useStyles = makeStyles(styles)

export default function Section(props) {
  const {
    className,
    children,
    title,
    color,
    border,
    ...rest
  } = props
  const classes = useStyles()
  const sectionClasses = classNames({
    [classes.section]: true,
    [classes[color]]: color,
    [classes[border]]: border,
    [className]: className !== undefined,
  })

  return (
    <div className={sectionClasses} {...rest}>
      <div className={classes.container}>
        {title !== undefined ? <h1 className={classes.title}>{title}</h1> : null}
        {children}
      </div>
    </div>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
  ]),
  border: PropTypes.oneOf([
    "bottom",
    "top"
  ]),
  children: PropTypes.node,
}
